<template>
	<Menu as="div" class="relative ml-3">
		<div>
			<MenuButton class="relative flex text-sm bg-white rounded-full focus:outline-none focus:ring-2 focus:ring-mx-orange focus:ring-offset-2">
				<span class="sr-only">
					Open user menu
				</span>
				<img
					v-if="hasSrc"
					class="inline-block w-8 h-8 rounded-full"
					:src="src"
					alt="avatar"
					height="32"
					width="32"
				>
				<svg
					v-else
					class="w-8 h-8 text-gray-300 rounded-full"
					fill="currentColor"
					viewBox="0 0 24 24"
				>
					<path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
				</svg>
			</MenuButton>
		</div>
		<transition
			enter-active-class="transition duration-200 ease-out"
			enter-from-class="transform scale-95 opacity-0"
			enter-to-class="transform scale-100 opacity-100"
			leave-active-class="transition duration-75 ease-in"
			leave-from-class="transform scale-100 opacity-100"
			leave-to-class="transform scale-95 opacity-0"
		>
			<MenuItems class="absolute right-0 z-10 w-48 py-1 mt-2 origin-top-right bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
				<!-- TODO: menu items, logout functionality -->
				<MenuItem v-slot="{ active }">
					<a href="#" :class="[active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700']">
						Logout
					</a>
				</MenuItem>
			</MenuItems>
		</transition>
	</Menu>
</template>

<script setup lang="ts">
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'

const props = defineProps({
	src: {
		type: String,
		default: ''
	}
})

const { src } = toRefs(props)

const hasSrc = computed(() => {
	return !!src.value?.length
})
</script>

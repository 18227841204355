<template>
	<div class="py-4">
		<h4
			v-if="title"
			class="my-4 text-xs text-gray-400"
		>
			{{ title }}
		</h4>
		<nav class="flex flex-col flex-1">
			<ul
				role="list"
				class="flex flex-col flex-1 gap-y-7"
			>
				<li>
					<ul
						role="list"
						class="-mx-2 space-y-1"
					>
						<li
							v-for="item in items"
							:key="item.name"
						>
							<NuxtLink
								v-if="!item.children"
								:to="item.href"
								:class="[isCurrentItem(item.href) ? 'text-mx-orange' : 'hover:bg-gray-50', 'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold text-gray-500']"
							>
								<component
									:is="item.icon"
									class="w-6 h-6 text-gray-500 shrink-0"
									aria-hidden="true"
								/>
								{{ item.name }}
							</NuxtLink>
							<Disclosure
								v-else
								v-slot="{ open }"
								as="div"
							>
								<DisclosureButton :class="[isCurrentItem(item.href) ? 'text-mx-orange' : 'hover:bg-gray-50', 'flex items-center w-full text-left rounded-md p-2 gap-x-3 text-sm leading-6 font-semibold text-gray-500']">
									<component
										:is="item.icon"
										class="w-6 h-6 text-gray-500 shrink-0"
										aria-hidden="true"
									/>
									{{ item.name }}
									<ChevronRightIcon :class="[open ? '-rotate-90' : 'rotate-90', 'ml-auto h-7 w-7 shrink-0 transition-all text-gray-500']" aria-hidden="true" />
								</DisclosureButton>
								<DisclosurePanel
									as="ul"
									class="px-2 mt-1"
								>
									<li
										v-for="subItem in item.children"
										:key="subItem.name"
									>
										<NuxtLink
											:to="subItem.href"
											:class="[isCurrentItem(subItem.href) ? 'bg-gray-50' : 'hover:bg-gray-50', 'block rounded-md p-2 ml-8 text-sm leading-6 text-gray-500 border-b']"
										>
											{{ subItem.name }}
										</NuxtLink>
									</li>
								</DisclosurePanel>
							</Disclosure>
						</li>
					</ul>
				</li>
			</ul>
		</nav>
	</div>
</template>

<script setup lang="ts">
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue'
import { ChevronRightIcon } from '@heroicons/vue/20/solid'
import type { NavItemSection, NavItem } from '@/components/admin/types'
import { useRoute } from 'vue-router'

const route = useRoute()

const props = defineProps({
	navSection: {
		type: Object as PropType<NavItemSection>,
		required: true
	}
})

const title = computed(() => props.navSection?.title || '')
const items = computed<NavItem[]>(() => props.navSection?.items || [])

const isCurrentItem = (href: String) => {
	return href === route.path
}
</script>

import {
	CalendarIcon,
	ChartPieIcon,
	DocumentDuplicateIcon,
	FolderIcon,
	HomeIcon,
	UsersIcon
} from '@heroicons/vue/24/outline'

export const navigation = [
	{
		title: 'Section 1',
		items: [
			{ name: 'Sidebar Navigation', href: '#', icon: HomeIcon },
			{
				name: 'Sidebar Navigation',
				icon: UsersIcon,
				children: [
					{ name: 'Sidebar Navigation', href: '#' },
					{ name: 'Sidebar Navigation', href: '#' },
					{ name: 'Sidebar Navigation', href: '#' }
				]
			},
			{
				name: 'Sidebar Navigation',
				icon: FolderIcon,
				children: [
					{ name: 'Sidebar Navigation', href: '#' },
					{ name: 'Sidebar Navigation', href: '#' },
					{ name: 'Sidebar Navigation', href: '#' },
					{ name: 'Sidebar Navigation', href: '#' }
				]
			},
			{ name: 'Sidebar Navigation', href: '#', icon: CalendarIcon },
			{ name: 'Sidebar Navigation', href: '#', icon: DocumentDuplicateIcon },
			{ name: 'Sidebar Navigation', href: '#', icon: ChartPieIcon }
		]
	},
	{
		title: 'Section 2',
		items: [
			{ name: 'Sidebar Navigation', href: '#', icon: HomeIcon },
			{
				name: 'Sidebar Navigation',
				icon: UsersIcon,
				children: [
					{ name: 'Sidebar Navigation', href: '#' },
					{ name: 'Sidebar Navigation', href: '#' },
					{ name: 'Sidebar Navigation', href: '#' }
				]
			},
			{
				name: 'Sidebar Navigation',
				icon: FolderIcon,
				children: [
					{ name: 'Sidebar Navigation', href: '#' },
					{ name: 'Sidebar Navigation', href: '#' },
					{ name: 'Sidebar Navigation', href: '#' },
					{ name: 'Sidebar Navigation', href: '#' }
				]
			},
			{ name: 'Sidebar Navigation', href: '#', icon: CalendarIcon },
			{ name: 'Sidebar Navigation', href: '#', icon: DocumentDuplicateIcon },
			{ name: 'Sidebar Navigation', href: '#', icon: ChartPieIcon }
		]
	}
]
